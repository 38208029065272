import './style.css';

function Error404() {
    return (
        <div id='error'>
            <h1>404</h1>
            <span>PAGE NOT FOUND</span>
        </div>
    )
}

export default Error404;